import React from "react";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";
import { RichText } from "../../styles/richText";
import { Address, Logo, Wrapper } from "./style";

const AddressWithLogo = ({ logoConfig, addressHtmlText }) => {
	return (
		<Wrapper>
			<Logo>
				<CloudinaryImage
					className="footerLogo"
					url={logoConfig.url}
					sizes={{
						default: {
							width: logoConfig.width,
							height: logoConfig.height,
						},
					}}
					quality="auto:best"
					fetchFormat="auto"
					dpr="auto"
					alt={logoConfig?.altTag}
				/>
			</Logo>
			<Address>
				<RichText
					className="address rich-text-wrapper"
					dangerouslySetInnerHTML={{ __html: addressHtmlText }}
				></RichText>
			</Address>
		</Wrapper>
	);
};

export default AddressWithLogo;
