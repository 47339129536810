import styled from "styled-components";
import { DESKTOP_SM_BP, MOBILE_L_BP } from "@website-builder/utilities-index";

export const Wrapper = styled.section`
	display: flex;
	flex-flow: row nowrap;
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-flow: row wrap;
		justify-content: center;
	}
`;

export const Logo = styled.div`
	flex-basis: 30%;
	padding: 20px;
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		padding: 10px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-basis: 100%;
	}
`;

export const Address = styled.div`
	flex-basis: 70%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	p {
		margin: 5px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-basis: 100%;
	}
`;
